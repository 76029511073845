import biodiversity from "./BiodiversityDetail";
import energyUse from "./EnergyUseDetail";
import greenhouseGas from "./GreenhouseGasDetail";
import irrigationWaterUse from "./IrrigationWaterUseDetail";
import landUse from "./LandUseDetail";
import soilCarbon from "./SoilCarbonDetail";
import soilConservation from "./SoilConservationDetail";
import waterQuality from "./WaterQualityOld";
import soilConservationInsights from "./SoilConservationInsights";

const metrics = {
    landUse,
    soilConservation,
    soilCarbon,
    irrigationWaterUse,
    energyUse,
    greenhouseGas,
    waterQuality,
    biodiversity,
    soilConservationInsights
};

export default metrics;
